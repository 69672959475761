import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const WhatsNewIcon = makeShortcode("WhatsNewIcon");
const Link = makeShortcode("Link");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "spanWhats-newspan-WhatsNewIcon-",
      "style": {
        "position": "relative"
      }
    }}><span>{`What's new`}</span>{` `}<WhatsNewIcon mdxType="WhatsNewIcon" /></h2>
    <p>{`  On February 8, 2024, ONRR added federal sales data:`}</p>
    <ul>
      <li parentName="ul"><Link href='/query-data/?dataType=Federal%20Sales' mdxType="Link">Query Federal Sales</Link>: use filters to customize the dataset</li>
      <li parentName="ul"><Link href='/downloads/federal-sales/' linkType='default' mdxType="Link">Download Data - Federal Sales</Link>: learn more about federal sales and download the full dataset</li>
    </ul>
    <Box borderBottom={'2px solid black'} mb={'-1rem'} mdxType="Box">
    </Box>
    <h3 {...{
      "id": "Latest-release",
      "style": {
        "position": "relative"
      }
    }}>{`Latest release`}</h3>
    <p>{`September 17, 2024 changes:`}</p>
    <ul>
      <li parentName="ul">{`Added `}<Link href="/how-revenue-works/coal-excise-tax/" linkType="default" mdxType="Link">{`2023 Coal Excise Tax`}</Link></li>
      <li parentName="ul">{`Added `}<Link href="/downloads/production-by-month/" linkType="default" mdxType="Link">{`monthly production data`}</Link></li>
      <li parentName="ul">{`Added `}<Link href="/downloads/disbursements-by-month/" linkType="default" mdxType="Link">{`monthly disbursements data`}</Link></li>
      <li parentName="ul">{`Added `}<Link href="/downloads/revenue/" linkType="default" mdxType="Link">{`monthly revenue data`}</Link></li>
      <li parentName="ul">{`Added `}<Link href="/downloads/production-by-disposition/" linkType="default" mdxType="Link">{`monthly production disposition data (OGOR-B)`}</Link></li>
      <li parentName="ul">{`Updated `}<Link href="/fact-sheet" linkType="default" mdxType="Link">{`monthly fact sheet`}</Link></li>
    </ul>
    <p>{`Review our `}<a parentName="p" {...{
        "href": "https://github.com/ONRR/nrrd/releases"
      }}>{`full release details`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      